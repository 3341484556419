<footer class="footer_module__1uDav">
    <div class="footer_widgets__3FIuV">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-6">
                    <section id="media_image-2" class="widget widget_media_image"><img width="300" height="186"
                            src="assets/img/logo-1024x635-1-300x186.png"
                            class="image wp-image-13357 footer-logo attachment-medium size-medium lazyloaded" alt=""
                            style="max-width: 100%; height: auto;" sizes="(max-width: 300px) 100vw, 300px"
                            data-ll-status="loaded"><noscript><img width="300" height="186"
                                src="assets/img/logo-1024x635-1-300x186.png"
                                class="image wp-image-13357  attachment-medium size-medium" alt=""
                                style="max-width: 100%; height: auto;"
                                sizes="(max-width: 300px) 100vw, 300px" /></noscript></section>
                    <section id="nav_menu-1" class="widget widget_nav_menu">
                        <div class="menu-footer-menu-container">
                            <ul id="menu-footer-menu" class="menu">
                                <li id="menu-item-13355"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13355"><a
                                        [routerLink]="['/contact-us']">Contact Us</a></li>
                                <li id="menu-item-13740"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13740"><a
                                        [routerLink]="['/promote']">Advertise With Us</a>
                                </li>
                                <li id="menu-item-13740"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13740"><a
                                        [routerLink]="['/report-issue']">Report An Issue</a>
                                </li>
                                <li id="menu-item-13742"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13742"><a
                                        [routerLink]="['/about-us']">About Us</a></li>
                                <li id="menu-item-13353"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13353"><a
                                        [routerLink]="['/terms--conditions']">Terms &amp;
                                        Conditions</a></li>
                                <li id="menu-item-13354"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13354"><a
                                        [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                                <li id="menu-item-14707"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-14707"><a
                                        [routerLink]="['/faqs']">FAQs</a></li>
                            </ul>
                        </div>
                    </section>
                    <section id="wilcity_mailchimp-1" class="widget widget_wilcity_mailchimp">
                        <div class="content-box_body__3tSRB">
                            <div class="widget-subsc">
                                <div class="widget-subsc__text"></div>
                                <div class="alert_module__Q4QZx alert_danger__2ajVf error hidden">
                                    <div class="alert_icon__1bDKL"><i class="la la-frown-o"></i></div>
                                    <div class="alert_content__1ntU3 err-msg"></div>
                                </div>
                                <form [formGroup]="mailchimpForm" (ngSubmit)="onSubmitSubscription()"
                                    class="wilcity-mailchimp-form widget-subsc__form">
                                    <div class="form-item">
                                        <input required formControlName="email" type="email" placeholder="eNewsletter">
                                    </div>
                                    <div class="form-submit">
                                        <button type="submit" aria-label="Subscribe"><i
                                                class="la la-envelope"></i></button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </section>
                </div>
                <div class="col-md-6 col-lg-6">
                </div>
            </div>
        </div>
    </div>
    <div class="footer_textWrap__Xc_Ht ">
        <div class="container">
            <div class="div-footer-copyright">
                <div class="footer_text__1FkcM">© 2025 Black Directory. All Rights Reserved </div>
                <div class="social-icon_module__HOrwr">
                    <a class="social-icon_item__3SLnb"
                        href="https://www.facebook.com/BlackDirectoryLtd/?modal=admin_todo_tour" rel="noopener"
                        target="_blank"><i class="fa fa-facebook"></i>
                    </a>
                    <a class="social-icon_item__3SLnb footer-social-icon-a twitter"
                        href="https://twitter.com/black_directory" rel="noopener" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100"
                            viewBox="0 0 24 24">
                            <path
                                d="M 2.8671875 3 L 9.7363281 12.818359 L 2.734375 21 L 5.3808594 21 L 10.919922 14.509766 L 15.460938 21 L 21.371094 21 L 14.173828 10.697266 L 20.744141 3 L 18.138672 3 L 12.996094 9.0097656 L 8.7988281 3 L 2.8671875 3 z">
                            </path>
                        </svg>
                    </a>
                    <a class="social-icon_item__3SLnb" href="https://www.instagram.com/black.directory/" rel="noopener"
                        target="_blank"><i class="fa fa-instagram"></i>
                    </a> <a class="social-icon_item__3SLnb" href="https://www.linkedin.com/company/black-directory"
                        rel="noopener" target="_blank"><i class="fa fa-linkedin"></i>
                    </a>
                    <a class="social-icon_item__3SLnb footer-social-icon-a tiktok" href="#" rel="noopener"
                        target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100"
                            viewBox="0 0 24 24">
                            <path
                                d="M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 6 5 L 18 5 C 18.56503 5 19 5.4349698 19 6 L 19 18 C 19 18.56503 18.56503 19 18 19 L 6 19 C 5.4349698 19 5 18.56503 5 18 L 5 6 C 5 5.4349698 5.4349698 5 6 5 z M 12 7 L 12 14 C 12 14.56503 11.56503 15 11 15 C 10.43497 15 10 14.56503 10 14 C 10 13.43497 10.43497 13 11 13 L 11 11 C 9.3550302 11 8 12.35503 8 14 C 8 15.64497 9.3550302 17 11 17 C 12.64497 17 14 15.64497 14 14 L 14 10.232422 C 14.616148 10.671342 15.259118 11 16 11 L 16 9 C 15.952667 9 15.262674 8.7809373 14.78125 8.3613281 C 14.299826 7.941719 14 7.4149911 14 7 L 12 7 z">
                            </path>
                        </svg>
                    </a>
                    <a class="social-icon_item__3SLnb footer-social-icon-a bluesky" href="#" rel="noopener"
                        target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                d="M111.8 62.2C170.2 105.9 233 194.7 256 242.4c23-47.6 85.8-136.4 144.2-180.2c42.1-31.6 110.3-56 110.3 21.8c0 15.5-8.9 130.5-14.1 149.2C478.2 298 412 314.6 353.1 304.5c102.9 17.5 129.1 75.5 72.5 133.5c-107.4 110.2-154.3-27.6-166.3-62.9l0 0c-1.7-4.9-2.6-7.8-3.3-7.8s-1.6 3-3.3 7.8l0 0c-12 35.3-59 173.1-166.3 62.9c-56.5-58-30.4-116 72.5-133.5C100 314.6 33.8 298 15.7 233.1C10.4 214.4 1.5 99.4 1.5 83.9c0-77.8 68.2-53.4 110.3-21.8z" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="wil-scroll-top " [ngClass]="{'active': pageYoffset > 1000}">
    <a title="To top" (click)="scrollToTop()">
        <i class="la la-angle-up"></i>
    </a>
</div>